import React, { useState, useRef, useEffect } from "react";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import classNames from "classnames";
import validate from "./validate";
import { getCSRFToken } from "../utils";
import Select from "react-select";
import mailcheck from "mailcheck";
import pickBy from "lodash/pickBy";
import creditCardType from "credit-card-type";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const Error = ({ visible, message }) =>
  visible ? (
    <small className="guest_checkout_form__error">{message}</small>
  ) : null;

export const Suggestion = ({ message, onClick }) =>
  message ? (
    <small className={"guest_checkout_form__input--suggestion"}>
      Did you mean{" "}
      <button className={"guest_checkout_form__link"} onClick={onClick}>
        {message}
      </button>
      ?
    </small>
  ) : null;

const selectStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "2px",
    border: "1px solid rgba(68, 68, 68, 0.2)",
    boxShadow: 0,
    "&:hover": {
      borderColor: state.isFocused
        ? "rgba(68, 68, 68, 0.4)"
        : "rgba(68, 68, 68, 0.2)"
    },
    "&:focus": {
      borderColor: state.isFocused
        ? "rgba(68, 68, 68, 0.4)"
        : "rgba(68, 68, 68, 0.2)"
    }
  }),
  valueContainer: base => ({
    ...base,
    "input[type='text']:focus": { boxShadow: "none" }
  })
};

const FieldSet = ({ legend, children }) => (
  <fieldset>
    <legend>
      <span>{legend}</span>
    </legend>
    {children}
  </fieldset>
);

const SelectInput = ({
  name,
  label,
  onChange,
  onBlur,
  options,
  value,
  errorMsg,
  shouldMarkError,
  inputRef,
  styles
}) => {
  const snakeCasedName = snakeCase(name);
  const camelCasedName = camelCase(name);
  const onChangeFn = onChange && onChange(camelCasedName);
  const onBlurFn = onBlur && onBlur(camelCasedName);
  const shouldMarkErrorResult =
    shouldMarkError && shouldMarkError(camelCasedName);
  const className = classNames("guest_checkout_form__input", {
    "guest_checkout_form__input--error": shouldMarkErrorResult
  });
  const errorMessage = errorMsg && errorMsg(camelCasedName);
  const inputRefFn = inputRef && inputRef(camelCasedName);

  return (
    <React.Fragment>
      <label htmlFor={snakeCasedName}>{label}</label>
      <Select
        options={options}
        value={options.find(option => value === option.value)}
        isClearable={true}
        label={label}
        name={name}
        onChange={onChangeFn}
        styles={styles}
        onBlur={onBlurFn}
        ref={inputRefFn}
        className={className}
      />
      <Error visible={shouldMarkErrorResult} message={errorMessage} />
    </React.Fragment>
  );
};

const Input = ({
  name,
  label,
  type,
  onChange,
  onBlur,
  value,
  errorMsg,
  shouldMarkError,
  inputRef,
  children
}) => {
  const snakeCasedName = snakeCase(name);
  const camelCasedName = camelCase(name);
  const inputType = type || "text";
  const onChangeFn = onChange && onChange(camelCasedName);
  const onBlurFn = onBlur && onBlur(camelCasedName);
  const shouldMarkErrorResult =
    shouldMarkError && shouldMarkError(camelCasedName);
  const className = classNames("guest_checkout_form__input", {
    "guest_checkout_form__input--error": shouldMarkErrorResult
  });
  const errorMessage = errorMsg && errorMsg(camelCasedName);
  const inputRefFn = inputRef && inputRef(camelCasedName);

  return (
    <React.Fragment>
      <label htmlFor={snakeCasedName}>{label}</label>
      <input
        type={inputType}
        name={name}
        id={snakeCasedName}
        value={value}
        onChange={onChangeFn}
        onBlur={onBlurFn}
        className={className}
        ref={inputRefFn}
      />
      <Error visible={shouldMarkErrorResult} message={errorMessage} />
      {children}
    </React.Fragment>
  );
};

const Checkbox = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  errorMsg,
  shouldMarkError,
  inputRef
}) => {
  const snakeCasedName = snakeCase(name);
  const camelCasedName = camelCase(name);
  const onChangeFn = onChange && onChange(camelCasedName);
  const onBlurFn = onBlur && onBlur(camelCasedName);
  const shouldMarkErrorResult =
    shouldMarkError && shouldMarkError(camelCasedName);
  const className = classNames("unsized guest_checkout_form__input", {
    "guest_checkout_form__input--error": shouldMarkErrorResult
  });
  const errorMessage = errorMsg && errorMsg(camelCasedName);
  const inputRefFn = inputRef && inputRef(camelCasedName);

  return (
    <React.Fragment>
      <input type="hidden" value={Number(value)} name={name} />
      <input
        type="checkbox"
        id={snakeCasedName}
        onChange={onChangeFn}
        onBlur={onBlurFn}
        className={className}
        ref={inputRefFn}
      />
      <label className="inline" htmlFor={snakeCasedName}>
        {label}
      </label>
      <Error visible={shouldMarkErrorResult} message={errorMessage} />
    </React.Fragment>
  );
};

const AuthorizeNet = ({
  values,
  handleFieldChange,
  handleBlur,
  shouldMarkError,
  errorMsg,
  mkInputRef
}) => (
  <React.Fragment>
    <div className="half first">
      <Input
        label="Number"
        name="authorization[number]"
        value={values.authorizationNumber}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        shouldMarkError={shouldMarkError}
        errorMsg={errorMsg}
        inputRef={mkInputRef}
      />
      <input
        type="hidden"
        name="authorization[card_type]"
        value={snakeCase(creditCardType(values.authorizationNumber)[0].type)}
      />
    </div>
    <div className="clearboth" />
    <div className="half first">
      <Input
        label="Cardholder Name"
        name="authorization[name]"
        value={values.authorizationName}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        shouldMarkError={shouldMarkError}
        errorMsg={errorMsg}
        inputRef={mkInputRef}
      />
    </div>
    <div className="clearboth" />
    <div className="half first">
      <div className="guest_checkout_form__grid guest_checkout_form__grid--gutters">
        <div className="guest_checkout_form__grid-cell">
          <Input
            label="Month"
            name="authorization[month]"
            value={values.authorizationMonth}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            shouldMarkError={shouldMarkError}
            errorMsg={errorMsg}
            inputRef={mkInputRef}
          />
        </div>
        <div className="guest_checkout_form__grid-cell">
          <Input
            label="Year"
            name="authorization[year]"
            value={values.authorizationYear}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            shouldMarkError={shouldMarkError}
            errorMsg={errorMsg}
            inputRef={mkInputRef}
          />
        </div>
      </div>
    </div>
    <div className="clearboth" />
    <div className="half first">
      <Input
        label="Verification Value"
        name="authorization[verification_value]"
        value={values.authorizationVerificationValue}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        shouldMarkError={shouldMarkError}
        errorMsg={errorMsg}
        inputRef={mkInputRef}
      />
    </div>
    <div className="clearboth" />
  </React.Fragment>
);

const Stripe = ({ error, value, postalCode }) => (
  <React.Fragment>
    <div className="half first">
      <CardElement
        options={{
          hidePostalCode: true,
          value: { postalCode },
          style: {
            base: { fontSize: "16px", fontWeight: 400, fontFamily: "Lato" },
            invalid: {
              color: "#997b7b",
              iconColor: "#997b7b"
            }
          }
        }}
      />
      <input type="hidden" value={value} name={"stripe_token"} />
      <Error visible={error} message={error} />
    </div>
    <div className="clearboth" />
  </React.Fragment>
);

const GuestCheckoutForm = ({
  errorMessages,
  countryOptions,
  zoneOptions,
  paymentMethod
}) => {
  const [values, setValue] = useState({
    email: "",
    emailConfirmation: "",
    billingAddressName: "",
    billingAddressAttention: "",
    billingAddressStreet: "",
    billingAddressSuite: "",
    billingAddressCity: "",
    billingAddressPostalCode: "",
    billingAddressPostalCodeId: "",
    billingAddressZoneId: "",
    billingAddressCountryId: "",
    shippingAddressName: "",
    shippingAddressAttention: "",
    shippingAddressStreet: "",
    shippingAddressSuite: "",
    shippingAddressCity: "",
    shippingAddressPostalCode: "",
    shippingAddressPostalCodeId: "",
    shippingAddressZoneId: "",
    shippingAddressCountryId: "",
    authorizationName: "",
    authorizationNumber: "",
    authorizationMonth: "",
    authorizationYear: "",
    authorizationVerificationValue: "",
    useShippingAddress: false,
    stripeToken: ""
  });

  const [touched, touch] = useState({});

  const [errors, setError] = useState({});

  const [suggestions, suggest] = useState({});

  const handleFieldChange = field => event =>
    setValue({ ...values, [camelCase(field)]: event.target.value });

  const handleSelectChange = field => option =>
    setValue({
      ...values,
      [camelCase(field)]: option === null ? "" : option.value
    });

  const handleCheckboxChange = field => event =>
    setValue({ ...values, [camelCase(field)]: event.target.checked });

  const handleBlur = field => () => {
    touch({ ...touched, [field]: true });
  };

  const shouldMarkError = field => {
    const hasError = errors[field];
    const shouldShow = touched[field];

    return hasError ? shouldShow : false;
  };

  const errorMsg = field => errorMessages[field];

  const fetchPostalCode = field => {
    if (values[field]) {
      return fetch(`addresses/postal_code?postal_code=${values[field]}`, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": getCSRFToken(),
          Accept: "application/json"
        }
      })
        .then(response => {
          return response.json();
        })
        .then(data =>
          setValue({
            ...values,
            [`${field}Id`]: data.id,
            [field.replace("PostalCode", "ZoneId")]: data.zone_id,
            [field.replace("PostalCode", "CountryId")]: data.country_id
          })
        );
    } else {
      return Promise.resolve();
    }
  };

  const setSuggestedEmail = email => suggest({ ...suggestions, email });

  const checkEmail = () => {
    mailcheck.run({
      email: values.email,
      suggested: suggestion => setSuggestedEmail(suggestion.full),
      empty: () => setSuggestedEmail("")
    });
  };

  const handleEmailBlur = field => () => {
    handleBlur(field)();
    checkEmail();
  };

  const pickSuggestedEmail = () => {
    setValue({ ...values, email: suggestions.email });
    setSuggestedEmail("");
    handleFocus("email");
  };

  const inputRef = useRef({});
  const formRef = useRef(null);

  const handleFocus = field => inputRef.current[field].focus();

  const isDisabled = () => Object.keys(errors).some(x => errors[x]);

  const fieldsToTouch = touched => {
    return Object.keys(touched).reduce((result, field) => {
      result[field] = true;
      return result;
    }, {});
  };

  const touchAllFields = () => {
    touch({ ...touched, ...fieldsToTouch(values) });
  };

  const stripe = useStripe();
  const elements = useElements();

  const stripeTokenHandler = async token =>
    await setValue({ ...values, stripeToken: token.id });

  const handleSubmit = async event => {
    event.preventDefault();
    touchAllFields();
    if (isDisabled()) {
      focusOnFirstFieldWithError();
    } else {
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        setError({ ...errors, stripeToken: result.error.message });
      } else {
        await stripeTokenHandler(result.token);
      }
      await fetch("/guest_checkout", {
        method: "POST",
        body: new FormData(formRef.current),
        redirect: "follow"
      }).then(response => {
        if (response.redirected) {
          window.location.href = response.url;
        }
      });
    }
  };

  const mkInputRef = field => input => (inputRef.current[field] = input);

  const focusOnFirstFieldWithError = () => {
    let fieldName = Object.keys(pickBy(errors))[0];
    fieldName && handleFocus(fieldName);
  };

  useEffect(
    () => setError({ ...errors, ...validate({ ...values, paymentMethod }) }),
    [values]
  );

  return (
    <form
      acceptCharset="UTF-8"
      method="post"
      onSubmit={handleSubmit}
      noValidate={true}
      ref={formRef}
    >
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      <FieldSet>
        <div className="group">
          <div className="half first">
            <Input
              label="E-mail"
              name="email"
              value={values.email}
              onChange={handleFieldChange}
              onBlur={handleEmailBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            >
              <Suggestion
                onClick={pickSuggestedEmail}
                message={suggestions.email}
              />
            </Input>
          </div>
          <div className="half">
            <Input
              label="E-mail confirmation"
              name="email_confirmation"
              value={values.emailConfirmation}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="clearboth" />
        </div>
      </FieldSet>
      <FieldSet legend="Billing Address">
        <div className="group">
          <div className="half first">
            <Input
              label="Name"
              name="billing_address[name]"
              value={values.billingAddressName}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="half">
            <Input
              label="Attention"
              name="billing_address[attention]"
              value={values.billingAddressAttention}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="clearboth" />
          <div className="half first">
            <Input
              label="Street"
              name="billing_address[street]"
              value={values.billingAddressStreet}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="half">
            <Input
              label="Suite"
              name="billing_address[suite]"
              value={values.billingAddressSuite}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="clearboth" />
          <div className="half first">
            <Input
              label="City"
              name="billing_address[city]"
              value={values.billingAddressCity}
              onChange={handleFieldChange}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="half">
            <Input
              label="Postal Code"
              name="billing_address[postal_code]"
              value={values.billingAddressPostalCode}
              onChange={handleFieldChange}
              onBlur={field => () => {
                fetchPostalCode(field).then();
                handleBlur(field)();
              }}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            >
              <input
                type="hidden"
                name="billing_address[postal_code_id]"
                value={`${values.billingAddressPostalCodeId}`}
              />
            </Input>
          </div>
          <div className="clearboth" />
          <div className="half first">
            <SelectInput
              name="billing_address[zone_id]"
              options={zoneOptions}
              value={values.billingAddressZoneId}
              label="State / Province"
              onChange={handleSelectChange}
              styles={selectStyles}
              onBlur={handleBlur}
              errorMsg={errorMsg}
              shouldMarkError={shouldMarkError}
              inputRef={mkInputRef}
            />
          </div>
          <div className="half">
            <SelectInput
              name="billing_address[country_id]"
              options={countryOptions}
              value={values.billingAddressCountryId}
              label="Country"
              onChange={handleSelectChange}
              styles={selectStyles}
              onBlur={handleBlur}
              shouldMarkError={shouldMarkError}
              errorMsg={errorMsg}
              inputRef={mkInputRef}
            />
          </div>
          <div className="clearboth" />
          <div className="unsized">
            <Checkbox
              name="use_shipping_address"
              label="Use different shipping address"
              value={values.useShippingAddress}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              inputRef={mkInputRef}
            />
          </div>
          <div className="clearboth" />
        </div>
      </FieldSet>
      {values.useShippingAddress ? (
        <FieldSet legend="Shipping Address">
          <div className="group">
            <div className="half first">
              <Input
                label="Name"
                name="shipping_address[name]"
                value={values.shippingAddressName}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="half">
              <Input
                label="Attention"
                name="shipping_address[attention]"
                value={values.shippingAddressAttention}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="clearboth" />
            <div className="half first">
              <Input
                label="Street"
                name="shipping_address[street]"
                value={values.shippingAddressStreet}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="half">
              <Input
                label="Suite"
                name="shipping_address[suite]"
                value={values.shippingAddressSuite}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="clearboth" />
            <div className="half first">
              <Input
                label="City"
                name="shipping_address[city]"
                value={values.shippingAddressCity}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="half">
              <Input
                label="Postal Code"
                name="shipping_address[postal_code]"
                value={values.shippingAddressPostalCode}
                onChange={handleFieldChange}
                onBlur={field => () => {
                  fetchPostalCode(field).then();
                  handleBlur(field)();
                }}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              >
                <input
                  type="hidden"
                  name="shipping_address[postal_code_id]"
                  value={`${values.shippingAddressPostalCodeId}`}
                />
              </Input>
            </div>
            <div className="clearboth" />
            <div className="half first">
              <SelectInput
                name="shipping_address[zone_id]"
                options={zoneOptions}
                value={values.shippingAddressZoneId}
                label="State / Province"
                onChange={handleSelectChange}
                styles={selectStyles}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="half">
              <SelectInput
                name="shipping_address[country_id]"
                options={countryOptions}
                value={values.shippingAddressCountryId}
                label="Country"
                onChange={handleSelectChange}
                styles={selectStyles}
                onBlur={handleBlur}
                shouldMarkError={shouldMarkError}
                errorMsg={errorMsg}
                inputRef={mkInputRef}
              />
            </div>
            <div className="clearboth" />
          </div>
        </FieldSet>
      ) : null}
      <FieldSet legend="Credit Card">
        <div className="group">
          {paymentMethod === "authorize_net" ? (
            <AuthorizeNet
              values={values}
              errorMsg={errorMsg}
              shouldMarkError={shouldMarkError}
              handleBlur={handleBlur}
              handleFieldChange={handleFieldChange}
              mkInputRef={mkInputRef}
            />
          ) : (
            <Stripe
              value={values.stripeToken}
              error={errors.stripeToken}
              postalCode={values.billingAddressPostalCode}
            />
          )}
        </div>
      </FieldSet>
      <div className="pagebuttons">
        <input type="submit" name="commit" value="Create" />
      </div>
    </form>
  );
};

const App = props => {
  const stripePromise = loadStripe(props.stripePublishableKey);
  return (
    <Elements stripe={stripePromise}>
      <GuestCheckoutForm {...props} />
    </Elements>
  );
};

export default App;
