import React from "react";
import Select from "react-select";
import styles from "../../selectStyles";
import { inputId, inputName } from "../utils";
import "../websites.scss";

class WebsiteRoleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  handleRoleSelect = selectedOption =>
    this.setState({ ...this.state, value: selectedOption.value });

  id = () => inputId(this.props.linkable, this.props.index, "role");

  getOptions = () =>
    this.props.options && this.props.options.length ? this.props.options : [];

  getValues = () =>
    this.getOptions().filter(option => option.value === this.state.value);

  render() {
    return (
      <li
        className="third select input required website-field"
        id={this.id() + "_input"}
      >
        <label
          htmlFor={this.id()}
          className="label"
          dangerouslySetInnerHTML={{ __html: "Role" + this.props.requiredIcon }}
        />
        <div className="website-field__select">
          <Select
            name={inputName(this.props.linkable, this.props.index, "role")}
            id={this.id()}
            styles={{ ...styles, container: base => ({ ...base, flex: 1 }) }}
            options={this.getOptions()}
            value={this.getValues()}
            onChange={this.handleRoleSelect}
          />
          {this.props.children}
        </div>
      </li>
    );
  }
}

export default WebsiteRoleSelect;
