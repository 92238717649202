import "@fancyapps/fancybox/dist/jquery.fancybox.js";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";

$(document).ready(() => {
  $('a.various[data-type="ajax"]').fancybox({
    ajax: {
      settings: {
        dataType: 'text'
      }
    }
  });
});