import React from "react";
import PropTypes from "prop-types";
import WebsiteRoleSelect from "./WebsiteRoleSelect";
import WebsiteTitleInput from "./WebsiteTitleInput";
import WebsiteUrlInput from "./WebsiteUrlInput";
import WebsiteIdHiddenInput from "./WebsiteIdHiddenInput";
import RemoveWebsiteLink from "./RemoveWebsiteLink";

const Website = ({
  id,
  url,
  linkable,
  index,
  roles,
  selectedRole,
  title,
  removeWebsiteHandler,
  removeIcon,
  requiredIcon
}) => (
  <ol>
    <WebsiteTitleInput
      linkable={linkable}
      index={index}
      value={title}
      requiredIcon={requiredIcon}
    />
    <WebsiteUrlInput
      linkable={linkable}
      index={index}
      value={url}
      requiredIcon={requiredIcon}
    />
    <WebsiteRoleSelect
      index={index}
      linkable={linkable}
      options={roles}
      value={selectedRole}
      requiredIcon={requiredIcon}
    >
      <RemoveWebsiteLink
        id={id}
        linkable={linkable}
        index={index}
        onClick={removeWebsiteHandler}
        removeIcon={removeIcon}
      />
    </WebsiteRoleSelect>
    <WebsiteIdHiddenInput index={index} linkable={linkable} value={id} />
  </ol>
);

Website.propTypes = {
  id: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  linkable: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })
  ).isRequired,
  selectedRole: PropTypes.number,
  removeWebsiteHandler: PropTypes.func.isRequired,
  removeIcon: PropTypes.string.isRequired,
  requiredIcon: PropTypes.string.isRequired
};

export default Website;
