import React from "react";
import Li from "./Li";
import HiddenInput from "./HiddenInput";

const ArrayInput = ({ path, value, onChange }) => (
  <Li path={path}>
    <input
      value={value}
      onChange={({ target: { value } }) => onChange(value.split(/\s*,\s*/))}
    />
    {value.map((val, index) => (
      <HiddenInput
        value={val}
        path={path}
        name={`config[${path}][value][]`}
        key={[path, index].join("_")}
      />
    ))}
  </Li>
);

export default ArrayInput;
