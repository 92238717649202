import "core-js/stable"; // Do not move lower or to a different file
import "regenerator-runtime/runtime"; // Do not move lower or to a different file
import "react-dates/esm/initialize"; // Do not move lower or to a different file
import React from "react";
import { render } from "react-dom";
import { deepCamelize } from "../utils";
import "../fancybox";
import "../advances_search_season_options/start.js";
import ProductsCheckboxes from "../ProductsCheckboxes";
import SignupForm from "../SignupForm";
import FinalizeCheckoutForm from "../FinalizeCheckoutForm";
import InterviewQuestionsAndAnswers from "../InterviewQuestionsAndAnswers";
import WebsitesForm from "../WebsitesForm";
import ExportLineItemCollectionButton from "../ExportLineItemCollectionButton";
import ConfigFilter from "../ConfigFilter";
import ConfigForm from "../ConfigForm";
import SiteRules from "../SiteRules";
import GuestCheckoutForm from "../GuestCheckoutForm";
import LocalTime from "local-time"

export const renderReactApp = dataAppName => {
  Array.from(document.querySelectorAll(`[data-app="${dataAppName.NAME}"]`)).map(
    node => {
      const props = deepCamelize(JSON.parse(node.getAttribute("data-json")));
      const Component = dataAppName.RootComponent;
      render(<Component {...props} />, node);
    }
  );
};

LocalTime.start();

$(document).ready(() => {
  renderReactApp(SignupForm);
  renderReactApp(ProductsCheckboxes);
  renderReactApp(FinalizeCheckoutForm);
  renderReactApp(InterviewQuestionsAndAnswers);
  renderReactApp(WebsitesForm);
  renderReactApp(ExportLineItemCollectionButton);
  renderReactApp(ConfigFilter);
  renderReactApp(SiteRules);
  renderReactApp(ConfigForm);
  renderReactApp(GuestCheckoutForm);
});
