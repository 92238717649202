const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = ( (typeof(requireFullName) !== 'undefined' && requireFullName) ? /^[a-zA-Z\-\'\.]+( [a-zA-Z\-\'\.]+)+$/ : /./ );

export const validate = ({
  name,
  email,
  password,
  passwordConfirmation,
  recaptcha
}) => {
  return {
    name: name.length === 0 || !NAME_REGEX.test(name),
    email: email.length === 0 || !EMAIL_REGEX.test(email),
    password: password.length < 8,
    passwordConfirmation:
      passwordConfirmation.length === 0 || password !== passwordConfirmation,
    recaptcha: !recaptcha
  };
};
