import React, { useState } from "react";
import Li from "./Li";
import HiddenInput from "./HiddenInput";
import "../configform.scss";

export const AddFieldButton = ({ onClick }) => (
  <button
    onClick={event => {
      event.preventDefault();
      onClick();
    }}
    className="config-form__button"
  >
    Add field
  </button>
);

const HashInput = ({ path, value, hashValue }) => {
  const [hState, setHState] = useState([
    ...Object.entries(value || {}).map(arr => ({
      key: arr[0],
      value: hashValue ? JSON.stringify(arr[1]) : arr[1]
    }))
  ]);

  const hiddenField = (key, value, hashValue) => {
    const parseValue = () => {
      try {
        return JSON.parse(value);
      } catch (_err) {
        return {};
      }
    };

    const many = () => (
      <React.Fragment>
        {Object.entries(parseValue()).map(pair => (
          <HiddenInput
            key={`path_${pair[0]}`}
            path={path}
            value={pair[1]}
            name={`config[${path}][value][${key}][${pair[0]}]`}
          />
        ))}
      </React.Fragment>
    );

    const single = () => (
      <HiddenInput
        path={path}
        value={value}
        name={`config[${path}][value][${key}]`}
      />
    );

    if (key && hashValue) {
      return many();
    } else if (key) {
      return single();
    } else {
      return null;
    }
  };

  const fields = hState.map((_obj, i) => (
    <React.Fragment key={i}>
      <Li path={path} className="half first">
        <input
          value={hState[i].key}
          onChange={({ target: { value } }) =>
            setHState([
              ...hState.slice(0, i),
              { key: value, value: hState[i].value },
              ...hState.slice(i + 1)
            ])
          }
        />
      </Li>
      <Li path={path} className="half">
        <input
          value={hState[i].value}
          onChange={({ target: { value } }) =>
            setHState([
              ...hState.slice(0, i),
              { key: hState[i].key, value },
              ...hState.slice(i + 1)
            ])
          }
        />
      </Li>
      {hiddenField(hState[i].key, hState[i].value, hashValue)}
    </React.Fragment>
  ));

  const addField = () => setHState([...hState, { key: "", value: "" }]);

  return (
    <React.Fragment>
      {fields}
      <AddFieldButton onClick={addField} />
    </React.Fragment>
  );
};

export default HashInput;
