import React from "react";
import PropTypes from "prop-types";

const RemoveWebsiteLink = ({ index, onClick, removeIcon }) => (
  <div className="website-field__button-wrapper">
    <button
      className="website-field__button website-field__button--remove"
      onClick={() => onClick(index)}
      dangerouslySetInnerHTML={{ __html: removeIcon }}
    />
  </div>
);

RemoveWebsiteLink.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  removeIcon: PropTypes.string.isRequired
};

export default RemoveWebsiteLink;
