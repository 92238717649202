import React, { useState } from "react";

export default ({ rows }) => {
  const [searchString, setValue] = useState("");
  const handleInput = event => setValue(event.target.value);
  const filter = (
    <form className="formtastic">
      <fieldset className="inputs">
        <ol>
          <li className="full input optional">
            <label className="label">Name</label>
            <input className="input" onChange={handleInput} />
          </li>
        </ol>
      </fieldset>
    </form>
  );
  const makeRow = row => (
    <tr key={row.name}>
      <td width="50%">
        <a className="highlight" href={row.url}>
          {row.name}
        </a>
      </td>
      <td>
        <pre style={{ margin: 0 }}>{JSON.stringify(row.value, null, 2)}</pre>
      </td>
    </tr>
  );
  const matchesName = ({ name }) => name.includes(searchString);
  const table = (
    <table id="configs" className="list">
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>{rows.filter(matchesName).map(makeRow)}</tbody>
    </table>
  );

  return (
    <React.Fragment>
      {filter}
      {table}
    </React.Fragment>
  );
};
