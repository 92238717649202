import React from "react";
import PropTypes from "prop-types";

const AddWebsiteLink = ({ onClick }) => (
  <ol>
    <li className="half first">
      <button className="website-field__button addlink" onClick={onClick}>
        Add another website
      </button>
    </li>
  </ol>
);

AddWebsiteLink.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddWebsiteLink;
