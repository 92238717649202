class FakeStripeCheckout {
  configure(obj) {
    this.token = obj.token;
    return this;
  }

  open(config) {
    var that = this;
    setTimeout(()=>{
      that.token({id: "tok_mastercard"});
    }, 300)
  }
}

const fakeStripeCheckout = new FakeStripeCheckout;

export {fakeStripeCheckout};