import each from "lodash/each";
import filter from "lodash/filter";
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import StripeComponent from "./StripeComponent";
import { fakeStripeCheckout } from "./FakeStripeCheckout";
import AuthorizeNetComponenet from "./AuthorizeNetComponent";
import { createPortal } from "react-dom";
import { extractFirstErrorMessageFromJsonApiReponse } from "./ServerSide";

const PaidModal = ({ translations, paymentMethod, setting }) => (
  <div key="paid-modal">
    <Modal
      isOpen={true}
      contentLabel="Paid"
      shouldCloseOnEsc={false}
      ariaHideApp={false}
    >
      <h1>{translations.checkoutCompleteTitle}</h1>
      <p>
        {translations.checkoutCompleteOrderInfo}
        {paymentMethod.technical == "check" ? (
          <strong>{translations.checkoutCompleteOrderOnHold}</strong>
        ) : null}
        {translations.checkoutCompleteTracking}
      </p>
      <p>
        You can view your order status by going to the{" "}
        <a href="/account">{translations.accountIndexTitle}</a> page and by
        clicking on <a href="/account/orders">View your orders</a>.
      </p>

      <p>
        We hope you enjoyed your shopping experience with {setting.companyName}.
        If you have any questions, please call us at {setting.phone} or email{" "}
        <a href="/contact">customer service</a>.
      </p>

      <p>
        <a href="/" className="button">
          Return to Home Page
        </a>
      </p>
    </Modal>
  </div>
);

const ErrorFlashNotice = ({ errorMsg }) =>
  errorMsg ? (
    <div id="flash" className="flash">
      <div className="flash-error">{errorMsg}</div>
    </div>
  ) : null;

class FinalizeCheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      processing: false,
      paying: false
    };
    this.finalizeClicked = this.finalizeClicked.bind(this);
    this.markAsPaying = this.markAsPaying.bind(this);
    this.paidWithStripe = this.paidWithStripe.bind(this);
    this.paidWithAuthorizeNet = this.paidWithAuthorizeNet.bind(this);
    this.paymentClosed = this.paymentClosed.bind(this);
    this.nonCreditCardPayment = this.nonCreditCardPayment.bind(this);
    this.completeCart = this.completeCart.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.editCart = document.getElementById("edit-cart");
    this.checkoutProcessing = document.getElementById("checkout-processing");
    this.checkoutShipping = document.getElementById("checkout-shipping");
    this.checkoutBilling = document.getElementById("checkout-billing");
    this.checkoutTax = document.getElementById("checkout-tax");
  }

  nonCreditCardPayment() {
    this.markAsPaying(true);
    $.post(this.props.paymentUrl)
      .done(() => this.completeCart())
      .fail(jqXHR => {
        this.markAsPaying(false);
        this.setState({
          errorMsg: extractFirstErrorMessageFromJsonApiReponse(
            jqXHR,
            "Cart completion failed"
          )
        });
      });
  }

  completeCart() {
    window.location.href = this.props.completeUrl;
  }

  finalizeClicked() {
    this.setState({ ...this.state, processing: true });
    const state = this.props.paymentMethod.cardAuthorization ? "Sale" : "Paid";
    if (state === "Paid") {
      this.nonCreditCardPayment();
    } else {
      this.setState({
        ...this.state,
        state,
        processing: false,
        errorMsg: null
      });
    }
  }

  paidWithStripe() {
    this.completeCart();
  }

  paidWithAuthorizeNet() {
    this.completeCart();
  }

  paymentClosed() {
    this.setState({ ...this.state, state: "Cart", processing: false });
  }

  markAsPaying(paying) {
    this.setState({ ...this.state, paying });
  }

  finalizeBtn() {
    const isDisabled = this.state.processing || this.state.paying;
    const btnName = {
      true: {
        true: "Finalizing...",
        false: "Pay to Finalize"
      },
      false: {
        true: "Finalizing...",
        false: "Finalize"
      }
    }[this.props.paymentMethod.cardAuthorization][isDisabled];
    let buttons = [
      <div key="finalize-btn" className="pagebuttons">
        <div className="secondary">
          <a href={this.props.cartUrl} className="button">
            Cancel
          </a>
        </div>
        <div>
          <button
            className="button alt"
            type="button"
            disabled={isDisabled}
            onClick={this.finalizeClicked}
          >
            {btnName}
          </button>
        </div>
        <ErrorFlashNotice errorMsg={this.state.errorMsg} />
      </div>
    ];

    const potentialEditButtons = [
      [
        <a
          className="button"
          key="edit-cart-path-button"
          href={this.props.cartUrl}
        >
          Edit
        </a>,
        this.editCart
      ],
      [
        <a
          className="button"
          key="checkout-processing-button"
          href={this.props.checkoutProcessingUrl}
        >
          Edit
        </a>,
        this.checkoutProcessing
      ],
      [
        <a
          className="button"
          key="checkout-shipping-button"
          href={this.props.checkoutShippingUrl}
        >
          Edit
        </a>,
        this.checkoutShipping
      ],
      [
        <a
          className="button"
          key="checkout-billing-button"
          href={this.props.checkoutBillingUrl}
        >
          Edit
        </a>,
        this.checkoutBilling
      ],
      [
        <a
          className="button"
          key="checkout-tax-button"
          href={this.props.checkoutBillingUrl}
        >
          Edit
        </a>,
        this.checkoutTax
      ]
    ];
    const editButtons = filter(
      potentialEditButtons,
      ([link, location]) => location && !isDisabled
    );
    each(editButtons, ([link, location]) => {
      buttons.push(createPortal(link, location));
    });
    return buttons;
  }

  render() {
    const elements = [];

    if (this.state.state === "Cart") {
      elements.push(this.finalizeBtn());
    }
    if (
      this.state.state === "Sale" &&
      this.props.paymentMethod.technical === "authorize_net"
    ) {
      elements.push(
        <AuthorizeNetComponenet
          showModal={true}
          key="authorize_net-component"
          state={this.state.state}
          lineItemCollectionId={this.props.lineItemCollectionId}
          amount={this.props.amount}
          paymentUrl={this.props.paymentUrl}
          onPaid={this.paidWithAuthorizeNet}
          onClosed={this.paymentClosed}
        />
      );
    }
    if (
      ["stripe", "stripe_customer"].includes(this.props.paymentMethod.technical)
    ) {
      elements.push(
        <StripeComponent
          key="stripe-component"
          state={this.state.state}
          lineItemCollectionId={this.props.lineItemCollectionId}
          amountInCents={this.props.amountInCents}
          email={this.props.email}
          paymentUrl={this.props.paymentUrl}
          companyName={this.props.setting.companyName}
          stripeKey={this.props.stripeKey}
          markAsPaying={this.markAsPaying}
          onPaid={this.paidWithStripe}
          onClosed={this.paymentClosed}
          StripeCheckout={this.props.test ? fakeStripeCheckout : null}
        />
      );
    }
    if (this.state.state === "Paid") {
      elements.push(<PaidModal {...this.props} />);
    }

    return <div>{elements}</div>;
  }
}

FinalizeCheckoutForm.propTypes = {
  state: PropTypes.oneOf(["Cart", "Sale", "Paid"]).isRequired,
  lineItemCollectionId: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  paymentUrl: PropTypes.string, // not required for check/po
  checkoutProcessingUrl: PropTypes.string.isRequired,
  checkoutShippingUrl: PropTypes.string.isRequired,
  checkoutBillingUrl: PropTypes.string.isRequired,
  completeUrl: PropTypes.string.isRequired,
  cartUrl: PropTypes.string.isRequired,
  stripeKey: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,

  test: PropTypes.oneOf([true, false]).isRequired
};

export default FinalizeCheckoutForm;
