import React from "react";
import { inputId, inputName } from "../utils";

const RemoveWebsiteInput = ({ id, linkable, index }) =>
  id ? (
    <input
      type="hidden"
      value="1"
      name={inputName(linkable, index, "_destroy")}
      id={inputId(linkable, index, "_destroy")}
    />
  ) : null;

export default RemoveWebsiteInput;
