import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

class ProductsCheckboxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: [] };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.tbody = document.getElementById("admin-products-list-tbody");
  }

  componentWillUnmount() {
    this.tbody = null;
  }

  handleToggle(id, checked) {
    let newChecked;
    if (checked) {
      newChecked = this.state.checked.concat(id);
    } else {
      newChecked = this.state.checked.filter(n => n !== id);
    }
    this.setState({
      checked: newChecked
    });
  }

  handleSelect() {
    this.setState({
      checked: this.props.products.map(p => p.id)
    });
  }

  handleUnselect() {
    this.setState({
      checked: []
    });
  }

  handleDelete() {
    if (confirm("Are you sure you want to delete these products?")) {
      $.ajax({
        url: "/admin/products",
        dataType: "json",
        contentType: "application/json; charset=UTF-8",
        type: "DELETE",
        data: JSON.stringify({
          product_ids: this.state.checked
        }),
        success: () => {
          window.location.href = window.location.href;
        }
      });
    }
  }

  render() {
    const notAllSelected =
      this.state.checked.length < this.props.products.length;
    const atLeastOneSelected = this.state.checked.length > 0;

    const portals = this.props.products.map((p, index) => {
      const isChecked = this.state.checked.indexOf(p.id) >= 0;
      return createPortal(
        <input
          type="checkbox"
          key={p.id}
          checked={isChecked}
          onChange={ev => {
            this.handleToggle(p.id, ev.target.checked);
          }}
        />,
        this.tbody.children[index].children[0]
      );
    });

    return (
      <form className="formtastic selectable">
        <fieldset className="actions">
          <ol>
            {notAllSelected ? (
              <li>
                <button type="button" onClick={this.handleSelect}>
                  Select All
                </button>
              </li>
            ) : null}
            {atLeastOneSelected ? (
              <li>
                <button type="button" onClick={this.handleUnselect}>
                  Unselect All
                </button>
              </li>
            ) : null}

            {atLeastOneSelected ? (
              <li className="secondary-actions">
                <button type="button" className="button other" onClick={this.handleDelete}>
                  <i className="fas fa-trash-alt" /> Delete Selected Products
                </button>
              </li>
            ) : null}
          </ol>
        </fieldset>

        {portals}
      </form>
    );
  }
}

ProductsCheckboxes.propTypes = {
  products: PropTypes.arrayOf(PropTypes.number)
};

const NAME = "ProductsCheckboxes";

export default { NAME: NAME, RootComponent: ProductsCheckboxes };
