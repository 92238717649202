import React from "react";
import Li from "./Li";

const StringInput = ({ path, value, onChange }) => (
  <Li path={path}>
    <input
      id={`config_${path}_value`}
      name={`config[${path}][value]`}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    />
  </Li>
);

export default StringInput;
