import React, { useState } from "react";
import SelectInput from "./components/SelectInput";
import SwitchInput from "./components/SwitchInput";
import NumberInput from "./components/NumberInput";
import ArrayInput from "./components/ArrayInput";
import StringInput from "./components/StringInput";
import HashInput from "./components/HashInput";
import DateInput from "./components/DateInput";
import TextArea from "./components/TextArea";
import Override from "./components/Override";
import { getCSRFToken } from "../utils";
import { FieldSet, SubmitButtons, IconRemove } from "../formComponents";
import "./configform.scss";
import RemoveInput from "./components/RemoveInput";
import snakeize from "snakeize";

export const RemoveButton = ({ onClick }) => (
  <button
    className="config-form__button config-form__button--remove-override"
    onClick={event => {
      onClick();
      event.preventDefault();
    }}
  >
    <IconRemove />
  </button>
);

export default ({ submitUrl, cancelUrl, configValues, input, sites }) => {
  const mkValues = configValues => ({
    ...snakeize(configValues).reduce((obj, config) => {
      obj[config.path] = config.value;
      return obj;
    }, {})
  });

  const {
    options,
    inputType,
    optional,
    min,
    max,
    step,
    multi,
    hashValue
  } = input;

  const [values, setValue] = useState(mkValues(configValues));
  const [toRemove, remove] = useState([]);

  const mkField = (path, inputType) => {
    const handleChange = value => setValue({ ...values, [path]: value });
    const props = { path: path, value: values[path], onChange: handleChange };
    switch (inputType) {
      case "select":
        return (
          <SelectInput
            {...props}
            options={options}
            multi={multi}
            optional={optional}
          />
        );
      case "switch":
        return <SwitchInput {...props} />;
      case "number":
        return <NumberInput {...props} min={min} max={max} step={step} />;
      case "array":
        return <ArrayInput {...props} />;
      case "string":
        return <StringInput {...props} />;
      case "hash":
        return <HashInput {...props} onChange={null} hashValue={hashValue} />;
      case "date":
        return <DateInput {...props} />;
      default:
        return <TextArea {...props} />;
    }
  };

  const siteName = path => {
    const site = sites.find(site => site.value === path);
    const onClick = () => {
      let { [path]: _, ...res } = values;
      setValue(res);
      remove([...toRemove, path]);
    };
    return site ? (
      <React.Fragment>
        {site.label}
        <RemoveButton onClick={onClick} />
      </React.Fragment>
    ) : (
      "Main"
    );
  };

  const availableSites = (sites, values) =>
    sites.filter(site => !Object.keys(values).includes(site.value));

  return (
    <form
      noValidate="novalidate"
      className="formtastic config"
      action={submitUrl}
      acceptCharset="UTF-8"
      method="post"
    >
      <input name="utf8" type="hidden" value="✓" />
      <input name="_method" type="hidden" value="patch" />
      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      {Object.keys(values)
        .sort()
        .map(path => (
          <FieldSet legend={siteName(path)} key={path}>
            {mkField(path, inputType)}
          </FieldSet>
        ))}
      <Override
        sites={availableSites(sites, values)}
        values={values}
        setValue={setValue}
      />
      {toRemove.map(path => (
        <RemoveInput path={path} key={`remove_${path}`} />
      ))}
      <SubmitButtons name="config" cancelUrl={cancelUrl} />
    </form>
  );
};
