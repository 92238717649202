import React from "react";
import Li from "./Li";
import Select from "react-select";
import styles from "../../selectStyles";

const SelectInput = ({ options, path, value, multi, optional, onChange }) => {
  const selectedOptions = value => {
    const values = Array.isArray(value) ? value : Array.of(value);
    return options.filter(option => values.includes(option.value));
  };
  const maybeValue = chosenOption =>
    chosenOption === null ? "" : chosenOption.value;
  const arrayOrNot = chosenOption =>
    Array.isArray(chosenOption)
      ? chosenOption.map(option => option.value)
      : maybeValue(chosenOption);

  return (
    <Li path={path}>
      <Select
        id={`config_${path}_value`}
        name={multi ? `config[${path}][value][]` : `config[${path}][value]`}
        styles={styles}
        isClearable={optional}
        isMulti={multi}
        backspaceRemovesValue={false}
        options={options}
        value={selectedOptions(value)}
        onChange={chosenOption => onChange(arrayOrNot(chosenOption))}
      />
    </Li>
  );
};

export default SelectInput;
