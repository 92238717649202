import React from "react";
import PropTypes from "prop-types";
import { inputId, inputName } from "../utils";
import "../websites.scss";

const WebsiteUrlInput = ({ value, index, linkable, requiredIcon }) => {
  const id = inputId(linkable, index, "url");
  return (
    <li
      className="third url input required stringish website-field"
      id={id + "_input"}
    >
      <label
        htmlFor={id}
        className="label"
        dangerouslySetInnerHTML={{ __html: "URL" + requiredIcon }}
      />
      <input
        maxLength="255"
        id={id}
        name={inputName(linkable, index, "url")}
        type="url"
        defaultValue={value}
        className="website-field__input"
      />
    </li>
  );
};

WebsiteUrlInput.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number.isRequired,
  linkable: PropTypes.string.isRequired
};

export default WebsiteUrlInput;
