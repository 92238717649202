const blue = "#51a7e8";
const darkBlue = "#1b84e7";
const gray = "#ddd";
const black = "#000";
const white = "#fff";
const shadow = "0 0 3px rgba(81, 167, 232, 0.5)";

const styles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 0,
    border: `1px solid ${gray}`,
    boxShadow: state.isFocused ? shadow : 0,
    borderColor: state.isFocused ? blue : gray,
    "&:hover": {
      borderColor: state.isFocused ? blue : gray
    },
    "&:focus": {
      borderColor: state.isFocused ? blue : gray
    }
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? darkBlue : white,
    color:
      state.isSelected && state.isFocused
        ? white
        : state.isSelected
        ? black
        : state.isFocused
        ? white
        : base.color
  }),
  valueContainer: base => ({
    ...base,
    "input[type='text']:focus": { boxShadow: "none" }
  })
};

export default styles;
