import React from "react";
import PropTypes from "prop-types";
import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";
// import ReCAPTCHA from "react-google-recaptcha";
import pickBy from "lodash/pickBy";
import map from "lodash/map";
import mailcheck from "mailcheck";
import Input, { Suggestion, Error } from "../shared/Input.js";
import { validate } from "./validate.js";
import { getCSRFToken } from "../utils";

const fieldsToTouch = touched => {
  return Object.keys(touched).reduce((result, field) => {
    result[field] = true;
    return result;
  }, {});
};

class SignupForm extends React.Component {
  inputElement = {};

  constructor(props) {
    super(props);
    this.state = {
      recaptchaRefreshToken: null,
      recaptchaResponseData: "",
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      category: "",
      touched: {
        name: false,
        email: false,
        password: false,
        passwordConfirmation: false,
        recaptcha: false
      },
      recaptcha: props.env === "test",
      suggestions: {
        email: ""
      }
    };
  }

  componentDidMount() {
    if ( typeof jQuery == "undefined" || typeof jQuery().selectmenu == "undefined" ) { return }
    jQuery(window).load(function(){
      var el = $("#user_category");
      if ( el.length > 0 ) { el.selectmenu("destroy") }
    });
  }

  handleFieldChange = field => event => {
    this.setState({ ...this.state, [field]: event.target.value });
  };

  handleBlur = field => () => {
    this.setState({ touched: { ...this.state.touched, [field]: true } });
  };

  setSuggestedEmail = email => {
    this.setState({ suggestions: { ...this.state.suggestions, email } });
  };

  checkEmail = () => {
    mailcheck.run({
      email: this.state.email,
      suggested: suggestion => this.setSuggestedEmail(suggestion.full),
      empty: () => this.setSuggestedEmail("")
    });
  };

  handleEmailBlur = () => () => {
    this.setState({ touched: { ...this.state.touched, email: true } });
    this.checkEmail();
  };

  pickSuggestedEmail = () => {
    this.setState({ ...this.state, email: this.state.suggestions.email });
    this.setSuggestedEmail("");
  };

  handleReCAPTCHA = (token/*, refreshToken */) => {
    if (typeof token === 'string') {
      this.setState({recaptchaResponseData: token, recaptcha: true});
    }
  };

  touchAllFields = () => this.setState({
    touched: {
      ...this.state.touched,
      ...fieldsToTouch(this.state.touched)
    }
  });

  focusOnFirstFieldWithError = errors => {
    let fieldName = Object.keys(pickBy(errors))[0];
    fieldName && this.inputElement[fieldName].focus();
  };

  handleSubmit = (errors, isDisabled) => event => {
    this.touchAllFields();
    if (isDisabled) {
      event.preventDefault();
      this.focusOnFirstFieldWithError(errors);
    }
  };

  render() {
    const errors = validate(this.state);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    const renderRecaptcha = this.props.recaptchaSitekey != null && this.props.recaptchaSitekey.length > 0;

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    const consentHtml = this.props.consentInput;
    let consent;
    if (consentHtml !== null) {
      consent = <div className="full" dangerouslySetInnerHTML={{ __html: consentHtml }} />;
    }

    return (
      <form
        className="new_user signup_form"
        id="new_user"
        action="/signup"
        acceptCharset="UTF-8"
        method="post"
        onSubmit={this.handleSubmit(errors, isDisabled)}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
        <fieldset>
          <div className="group">
            <div className="half first">
              <Input
                name="name"
                label={((typeof(requireFullName) !== 'undefined' && requireFullName) ? "Full Name" : "Name") + this.props.requiredIcon}
                model="user"
                value={this.state.name}
                onChange={this.handleFieldChange}
                onBlur={this.handleBlur}
                errorMsg={this.props.errorMessages.name}
                shouldMarkError={shouldMarkError}
                inputRef={el => {
                  this.inputElement["name"] = el;
                }}
              />
            </div>
            <div className="half">
              <Input
                name="email"
                label={"Email" + this.props.requiredIcon}
                model="user"
                value={this.state.email}
                onChange={this.handleFieldChange}
                onBlur={this.handleEmailBlur}
                errorMsg={this.props.errorMessages.email}
                shouldMarkError={shouldMarkError}
                inputRef={el => {
                  this.inputElement["email"] = el;
                }}
              >
                <Suggestion
                  onClick={this.pickSuggestedEmail}
                  message={this.state.suggestions.email}
                />
              </Input>
            </div>
            <div className="clearboth" />
            <div className="half first">
              <Input
                name="password"
                label={"Password" + this.props.requiredIcon}
                model="user"
                type="password"
                value={this.state.password}
                onChange={this.handleFieldChange}
                onBlur={this.handleBlur}
                errorMsg={this.props.errorMessages.password}
                shouldMarkError={shouldMarkError}
                inputRef={el => {
                  this.inputElement["password"] = el;
                }}
              />
            </div>
            <div className="half">
              <Input
                name="passwordConfirmation"
                label={"Confirm Password" + this.props.requiredIcon}
                model="user"
                type="password"
                value={this.state.passwordConfirmation}
                onChange={this.handleFieldChange}
                onBlur={this.handleBlur}
                errorMsg={this.props.errorMessages.passwordConfirmation}
                shouldMarkError={shouldMarkError}
                inputRef={el => {
                  this.inputElement["passwordConfirmation"] = el;
                }}
              />
            </div>
            <div className="clearboth" />
            {this.props.customerCategories.length > 0 &&
            <div className="half first">
              <label
                htmlFor="user_category"
                dangerouslySetInnerHTML={{ __html: "Customer Category" + this.props.requiredIcon }}
              />
              <select
                name="user[category]"
                id="user_category"
                value={this.state.category}
                onChange={this.handleFieldChange("category")}
              >
                {map(
                  this.props.customerCategories,
                  (customerCategory, index) => (
                    <option value={customerCategory} key={index}>
                      {customerCategory}
                    </option>
                  )
                )}
              </select>
            </div>
            }
            {this.props.customerCategories.length > 0 &&
            <div className="clearboth" />
            }
            {consent}
          </div>
        </fieldset>
        {renderRecaptcha &&
          <ReCaptchaProvider
            siteKeyV3={this.props.recaptchaSitekey}
          >
            <ReCaptchaV3 action="signup" callback={this.handleReCAPTCHA} />
            <input
              type="hidden"
              id="g-recaptcha-response-data-signup"
              name="g-recaptcha-response-data"
              value={this.state.recaptchaResponseData}
            />
          </ReCaptchaProvider>
        }
        <div className="pagebuttons">
          <span
            className="secondary"
            dangerouslySetInnerHTML={{ __html: this.props.secondaryButtons }}
          />
          <input type="submit" name="commit" value="Create" />
        </div>
      </form>
    );
  }
}

SignupForm.propTypes = {
  errorMessages: PropTypes.objectOf(PropTypes.string),
  recaptchaSitekey: PropTypes.string.isRequired,
  consentInput: PropTypes.string,
  customerCategories: PropTypes.arrayOf(PropTypes.string),
  secondaryButtons: PropTypes.string,
  env: PropTypes.string,
  requiredIcon: PropTypes.string.isRequired
};

export default SignupForm;
