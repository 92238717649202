import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { extractFirstErrorMessageFromJsonApiReponse } from "./ServerSide";
import moment from "moment";
import "./modal.scss";

const SelectMonth = ({ value, onChange, disabled }) => (
  <select
    id="authorization_month"
    className="half first"
    value={value}
    onChange={ev => {
      onChange(ev.target.value);
    }}
    disabled={disabled}
  >
    {Array(12)
      .fill(1)
      .map((x, y) => x + y)
      .map(month => (
        <option key={`month-${month}`} value={month}>
          {month}
        </option>
      ))}
  </select>
);

const SelectYear = ({ value, onChange, disabled }) => (
  <select
    id="authorization_year"
    className="half"
    value={value}
    onChange={ev => onChange(ev.target.value)}
    disabled={disabled}
  >
    {Array(11)
      .fill(moment().year())
      .map((x, y) => x + y)
      .map(year => (
        <option key={`year-${year}`} value={year}>
          {year}
        </option>
      ))}
  </select>
);

class AuthorizeNetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      name: "",
      cardType: "visa",
      cardNumber: "",
      expirationMonth: "1",
      expirationYear: `${moment().year()}`,
      verificationValue: "",
      processing: false
    };
    this.submitPaymentForm = this.submitPaymentForm.bind(this);
    this.change = this.change.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  submitPaymentForm(event) {
    event.preventDefault();
    if (this.state.processing) {
      return;
    }

    this.setState({ processing: true });
    $.post(this.props.paymentUrl, {
      "authorization[name]": this.state.name,
      "authorization[card_type]": this.state.cardType,
      "authorization[number]": this.state.cardNumber,
      "authorization[month]": this.state.expirationMonth,
      "authorization[year]": this.state.expirationYear,
      "authorization[verification_value]": this.state.verificationValue
    })
      .done(() => {
        this.props.onPaid();
        // processing: false, not set on purpose
      })
      .fail(jqXHR => {
        const errorMsg = extractFirstErrorMessageFromJsonApiReponse(
          jqXHR,
          "Credit card authorization failed"
        );
        this.setState({
          errorMsg,
          processing: false
        });
      });
  }

  change(field, value) {
    this.setState({ [field]: value });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.props.onClosed();
  }

  render() {
    const disabled = this.state.processing;
    const payBtnName = disabled ? "Processing..." : `Pay ${this.props.amount}`;
    return (
      <div key="authorize-net-modal">
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="Credit Card Payment"
          ariaHideApp={false}
          className="ReactModal__Content"
          overlayClassName="ReactModal__Overlay"
        >
          <h1>Credit Card Payment</h1>
          {this.errorFlashNotice()}

          <form
            action=""
            acceptCharset="UTF-8"
            method="post"
            onSubmit={this.submitPaymentForm}
          >
            <input name="utf8" type="hidden" value="✓" />
            <fieldset>
              <div id="credit_card_details" className="group">
                <div className="formrow">
                  <label htmlFor="authorization_name">
                    Card Holder (Full Name)
                  </label>
                  <input
                    type="text"
                    value={this.state.name}
                    id="authorization_name"
                    onChange={ev => {
                      this.change("name", ev.target.value);
                    }}
                    disabled={disabled}
                  />
                </div>
                <div className="formrow">
                  <label htmlFor="authorization_card_type">
                    Card Type
                  </label>
                  <select
                    id="authorization_card_type"
                    value={this.state.cardType}
                    onChange={ev => {
                      this.change("cardType", ev.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option value="visa">Visa</option>
                    <option value="master">MasterCard</option>
                    <option value="discover">Discover</option>
                    <option value="american_express">American Express</option>
                  </select>
                </div>
                <div className="formrow">
                  <label htmlFor="authorization_number">
                    Card Number
                  </label>
                  <input
                    id="authorization_number"
                    type="text"
                    value={this.state.cardNumber}
                    onChange={ev => {
                      this.change("cardNumber", ev.target.value);
                    }}
                    disabled={disabled}
                  />
                </div>
                <div className="formrow">
                  <label htmlFor="authorization_month">
                    Expiration Date (Month, Year)
                  </label>
                  <SelectMonth
                    value={this.state.expirationMonth}
                    onChange={this.change.bind(undefined, "expirationMonth")}
                    disabled={disabled}
                  />
                  <SelectYear
                    value={this.state.expirationYear}
                    onChange={this.change.bind(undefined, "expirationYear")}
                    disabled={disabled}
                  />
                </div>
                <div className="formrow">
                  <label htmlFor="authorization_verification_value">
                    Security Code &nbsp;{" "}
                    <a style={{ fontWeight: "normal" }} href="/security_code" rel="external" target="_blank">
                      Help?
                    </a>
                  </label>
                  <input
                    type="text"
                    id="authorization_verification_value"
                    value={this.state.verificationValue}
                    onChange={ev => {
                      this.change("verificationValue", ev.target.value);
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
            </fieldset>
            <div className="pagebuttons">
              <span className="secondary">
                <button
                  className="button"
                  disabled={disabled}
                  onClick={this.handleCloseModal}
                  type="button"
                >
                  Cancel
                </button>
              </span>
              <input
                type="submit"
                name="commit"
                value={payBtnName}
                className="button alt"
                disabled={disabled}
              />
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  errorFlashNotice() {
    if (!this.state.errorMsg) {
      return null;
    }

    return (
      <div id="flash" className="flash">
        <div className="flash-error">{this.state.errorMsg}</div>
      </div>
    );
  }
}

AuthorizeNetComponent.propTypes = {
  state: PropTypes.oneOf(["Cart", "Sale", "Paid"]).isRequired,
  lineItemCollectionId: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  paymentUrl: PropTypes.string.isRequired,
  onPaid: PropTypes.func.isRequired
};

export default AuthorizeNetComponent;
