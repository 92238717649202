import React from "react";
import Li from "./Li";
import { InputNumber } from "antd";

const NumberInput = ({ path, min, max, step, value, onChange }) => (
  <Li path={path}>
    <InputNumber
      id={`config_${path}_value`}
      name={`config[${path}][value]`}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={value => onChange(value)}
    />
  </Li>
);

export default NumberInput;
