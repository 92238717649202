import {
  MATCHING,
  NON_MATCHING,
  ANY,
  MATCHING_PUBLISHER,
  MATCHING_IMPRINT,
} from "./constants";

export default function isValid(choices) {
  return !!(
    choices.siteId &&
    choices.rule   &&
    ((choices.rule == MATCHING           && choices.formatId   )  ||
     (choices.rule == NON_MATCHING       && choices.formatId   )  ||
     (choices.rule == ANY                                      )  ||
     (choices.rule == MATCHING_PUBLISHER && choices.publisherId)  ||
     (choices.rule == MATCHING_IMPRINT   && choices.imprintId  ))
  );
}

