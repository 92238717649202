import React from "react";

export const SubmitButtons = ({ name, cancelUrl }) => (
  <fieldset className="actions">
    <ol>
      <li className="action button_action " id={`${name}_submit_action`}>
        <button name="button" type="submit">
          Save
        </button>
      </li>
      <li className="action link_action">
        &nbsp; or &nbsp;
        <a href={cancelUrl}>Cancel</a>
      </li>
    </ol>
  </fieldset>
);

export const IconRemove = () => (
  <i className={"far fa-times-circle tla-remove"} />
);

export const FieldSet = ({ legend, children, className = "inputs" }) => (
  <fieldset className={className}>
    <legend>
      <span>{legend}</span>
    </legend>
    <ol>{children}</ol>
  </fieldset>
);
