import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";
import styles from "../../selectStyles";
import { FieldSet } from "../../formComponents";
import "../configform.scss";

const Button = ({ override, values, setValue }) => (
  <li className="half">
    <button
      className="config-form__button config-form__button--add-override"
      disabled={!override}
      onClick={event => {
        setValue({ ...values, [override]: values["root"] });
        event.preventDefault();
      }}
    >
      Add
    </button>
  </li>
);

const Override = ({ sites, values, setValue }) => {
  const [override, setOverride] = useState(undefined);
  const chosenOption = () =>
    sites.find(option => option.value === override) || null;

  return isEmpty(sites) ? null : (
    <FieldSet legend={"Override"}>
      <li className="half first">
        <Select
          styles={styles}
          options={sites}
          value={chosenOption()}
          onChange={({ value }) => setOverride(value)}
        />
      </li>
      {override ? (
        <Button setValue={setValue} values={values} override={override} />
      ) : null}
    </FieldSet>
  );
};

export default Override;
