import React from "react";
import { Menu, Dropdown, Button, Icon, Checkbox, Row, Col } from "antd";
import chunk from "lodash/chunk";
import map from "lodash/map";
import filter from "lodash/filter";
import qs from "qs";
import PropTypes from "prop-types";

class ExportLineItemCollectionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedKeys: [],
      openKeys: [],
      columns: new Set(
        map(filter(this.props.columns, c => c.default), c => c.technical)
      )
    };
  }

  onDropdownVisibleChange = visible => {
    this.setState({
      visible
    });
  };

  onColumnCheck = (column, ev) => {
    const columns = new Set(this.state.columns);
    if (ev.target.checked) {
      columns.add(column);
    } else {
      columns.delete(column);
    }
    this.setState({
      columns
    });
  };

  onExportSelected = () => {
    const query = qs.stringify(
      { "columns[]": [...this.state.columns] },
      { indices: false }
    );
    const path = `${this.props.exportUrl}?${query}`;
    window.open(path, "_blank");
  };

  render() {
    const googleTagManagerIntegratedClassName = "export-xlsx";

    const menuItems = map(chunk(this.props.columns, 3), (group, mindex) => (
      <Menu.Item key={mindex}>
        <Row>
          {map(group, (column, cindex) => (
            <Col span={8} key={cindex}>
              <Checkbox
                checked={this.state.columns.has(column.technical)}
                onChange={this.onColumnCheck.bind(null, column.technical)}
              >
                {column.name}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Menu.Item>
    ));

    const menu = (
      <Menu
        multiple
        openKeys={this.state.openKeys}
        style={{ minWidth: "700px" }}
      >
        {menuItems}
        <Menu.Item key="btns">
          <Button
            type="primary"
            icon="download"
            onClick={this.onExportSelected}
            className={googleTagManagerIntegratedClassName}
          >
            {`${this.props.button} `}
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <span>
        <Dropdown
          visible={this.state.visible}
          overlay={menu}
          onVisibleChange={this.onDropdownVisibleChange}
          placement="bottomCenter"
        >
          <a className="ant-dropdown-link" href="#">
            {`${this.props.label} `}
            <Icon type="down" />
          </a>
        </Dropdown>
      </span>
    );
  }
}

ExportLineItemCollectionButton.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      technical: PropTypes.string,
      default: PropTypes.bool
    })
  ),
  exportUrl: PropTypes.string,
  label: PropTypes.string,
  button: PropTypes.string
};

export default ExportLineItemCollectionButton;
