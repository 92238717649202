import React, { useState } from "react";
import moment from "moment";
import Li from "./Li";
import HiddenInput from "./HiddenInput";
import SingleDatePicker from "react-dates/esm/components/SingleDatePicker";
import "react-dates/lib/css/_datepicker.css";

const DateInput = ({ path, value, onChange }) => {
  const [focused, setFocus] = useState(false);
  const onFocusChange = ({ focused }) => setFocus(focused);
  const date = value =>
    moment(value, moment.ISO_8601).isValid() ? moment(value) : null;
  const onDateChange = date =>
    moment(date, moment.ISO_8601).isValid()
      ? onChange(date.format("YYYY-MM-DD"))
      : undefined;

  return (
    <Li path={path}>
      <SingleDatePicker
        date={date(value)}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={onFocusChange}
      />
      <HiddenInput path={path} value={value} />
    </Li>
  );
};

export default DateInput;
