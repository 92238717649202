import map from "lodash/map";
import find from "lodash/find";
import moment from "moment";

import React from "react";
import PropTypes from "prop-types";

import SingleDatePicker from "react-dates/esm/components/SingleDatePicker";

import "react-dates/lib/css/_datepicker.css";
import "./compat.css";

const backendDateFormat = "YYYY-MM-DD";
const frontendDateFormat = backendDateFormat;

const yearStringToYear = string => {
  return parseInt(string) || null;
};

const findPublishingSeason = (yearSeason, seasons) => {
  return find(seasons, s => {
    return yearSeason && s.yearSeason == yearSeason;
  });
};

const findPublishingYear = (year, years) => {
  return find(years, y => {
    return year && y.year === year;
  });
};

class AdvancedSearchSeasonOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      season: this.findPublishingSeason(props.season),
      year: this.findPublishingYear(props.year),

      begin:
        props.releaseOnFrom && moment(props.releaseOnFrom, backendDateFormat),
      end: props.releaseOnTo && moment(props.releaseOnTo, backendDateFormat),

      beginFocused: false,
      endFocused: false
    };
    this.seasonSelected = this.seasonSelected.bind(this);
    this.yearSelected = this.yearSelected.bind(this);
    this.dateSelected = this.dateSelected.bind(this);
  }

  componentDidMount() {
    if ( typeof jQuery == "undefined" || typeof jQuery().selectmenu == "undefined" ) { return }
    jQuery(window).load(function(){
      var arr = ["#search_season_available", "#search_year_available"];
      jQuery.each(arr, function(index, id) {
        var el = jQuery(id);
        if ( el.length > 0 ) { el.selectmenu("destroy") }
      });
    });
  }

  findPublishingSeason(yearSeason) {
    return findPublishingSeason(yearSeason, this.props.seasons);
  }

  findPublishingYear(yearString) {
    return findPublishingYear(yearString, this.props.years);
  }

  seasonSelected(yearSeason) {
    const season = this.findPublishingSeason(yearSeason);
    let year;
    if (this.state.year && !season) {
      year = this.state.year;
    } else {
      year = null;
    }
    const begin =
      (season && moment(season.begin, frontendDateFormat)) || this.state.begin;
    const end =
      (season && moment(season.end, frontendDateFormat)) || this.state.end;
    this.setState({
      season,
      year,
      begin,
      end
    });
  }

  yearSelected(yearInt) {
    const year = this.findPublishingYear(yearInt);

    let season;
    if (this.state.season && !year) {
      season = this.state.season;
    } else {
      season = null;
    }

    const begin =
      (year && moment(year.begin, frontendDateFormat)) || this.state.begin;
    const end =
      (year && moment(year.end, frontendDateFormat)) || this.state.end;

    this.setState({
      season,
      year,
      begin,
      end
    });
  }

  dateSelected(name, value) {
    const previous = this.state[name];
    if (!!previous !== !!value || (value && !value.isSame(previous, "day"))) {
      this.setState({
        season: null,
        year: null,
        [name]: value
      });
    }
  }

  render() {
    const optionsForSeasons = map(this.props.seasons, s => {
      return (
        <option key={s.yearSeason} value={s.yearSeason}>
          {s.name}
        </option>
      );
    });
    const optionsForYears = map(this.props.years, y => {
      return (
        <option key={y.year} value={y.year.toString()}>
          {y.year}
        </option>
      );
    });
    const season = this.state.season;
    const year = this.state.year;

    return (
      <div>
        <div className="half first">
          <label htmlFor="search_season_available">Publishing Season</label>
          <select
            name="search[season_available]"
            id="search_season_available"
            onChange={e => this.seasonSelected(e.target.value)}
            value={season ? season.yearSeason : ""}
          >
            <option value="" />
            {optionsForSeasons}
          </select>
        </div>

        <div className="half">
          <label htmlFor="search_year_available">Publishing Year</label>
          <select
            name="search[year_available]"
            id="search_year_available"
            onChange={e => this.yearSelected(yearStringToYear(e.target.value))}
            value={((year && year.year) || "").toString()}
          >
            <option value="" />
            {optionsForYears}
          </select>
        </div>
        <div className="clearboth" />

        <div className="half first">
          <label htmlFor="search[product_formats_release_on_from]">
            <div className="label-text">Date Available (Minimum)</div>
          </label>
          <SingleDatePicker
            id="search[product_formats_release_on_from]"
            placeholder="YYYY-MM-DD"
            displayFormat={() => frontendDateFormat}
            date={this.state.begin}
            onDateChange={this.dateSelected.bind(null, "begin")}
            focused={this.state.beginFocused}
            onFocusChange={({ focused }) =>
              this.setState({ beginFocused: focused })
            }
            isOutsideRange={() => false}
          />
        </div>
        <div className="half">
          <label htmlFor="search[product_formats_release_on_to]">
            <div className="label-text">Date Available (Maximum)</div>
          </label>
          <SingleDatePicker
            id="search[product_formats_release_on_to]"
            placeholder="YYYY-MM-DD"
            displayFormat={() => frontendDateFormat}
            date={this.state.end}
            onDateChange={this.dateSelected.bind(null, "end")}
            focused={this.state.endFocused}
            onFocusChange={({ focused }) =>
              this.setState({ endFocused: focused })
            }
            isOutsideRange={() => false}
          />
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}
AdvancedSearchSeasonOptions.propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      season: PropTypes.string,
      yearSeason: PropTypes.string,
      begin: PropTypes.string, //YYYY-MM-DD format
      end: PropTypes.string, //YYYY-MM-DD format
      name: PropTypes.string
    })
  ).isRequired,
  years: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      begin: PropTypes.string, //YYYY-MM-DD format
      end: PropTypes.string //YYYY-MM-DD format
    })
  ).isRequired,
  season: PropTypes.string,
  year: PropTypes.number,
  releaseOnFrom: PropTypes.string, //YYYY-MM-DD format
  releaseOnTo: PropTypes.string //YYYY-MM-DD format
};

export default AdvancedSearchSeasonOptions;
