import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { extractFirstErrorMessageFromJsonApiReponse } from "./ServerSide";

class StripeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeHandler: null,
      errorMsg: null
    };
    this.stripeLoaded = this.stripeLoaded.bind(this);
    this.openStripeModal = this.openStripeModal.bind(this);
    this.submitStripeToken = this.submitStripeToken.bind(this);
    this.retryClicked = this.retryClicked.bind(this);

    this.stripeHandler = null;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.stripe.com/checkout.js";
    script.async = true;
    script.onload = this.stripeLoaded;
    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.state == "Sale" && this.state.stripeHandler) {
      if (prevProps.state != "Sale" || !prevState.stripeHandler) {
        this.openStripeModal();
      }
    }
  }

  stripeLoaded() {
    const stripeHandler = this.getStripeCheckout().configure({
      key: this.props.stripeKey,
      locale: "auto",
      token: this.submitStripeToken,
      closed: this.props.onClosed
    });
    this.setState({ stripeHandler });
  }

  submitStripeToken(token) {
    this.props.markAsPaying(true);
    $.post(this.props.paymentUrl, {
      stripeToken: token.id
    })
      .done(() => this.props.onPaid())
      .fail(jqXHR => {
        this.props.markAsPaying(false);
        this.setState({
          errorMsg: extractFirstErrorMessageFromJsonApiReponse(
            jqXHR,
            "Credit card authorization failed"
          )
        });
      });
  }

  openStripeModal() {
    this.setState({ errorMsg: null });
    this.state.stripeHandler.open({
      name: this.props.companyName,
      description: `Order ${this.props.lineItemCollectionId}`,
      amount: this.props.amountInCents,
      email: this.props.email
    });
  }

  retryClicked() {
    this.openStripeModal();
  }

  getStripeCheckout() {
    return this.props.StripeCheckout || window.StripeCheckout;
  }

  render() {
    if (this.state.errorMsg) {
      return (
        <Modal
          key="error-modal"
          isOpen={true}
          contentLabel="Error"
          shouldCloseOnEsc={false}
          ariaHideApp={false}
        >
          <h1>Error</h1>
          <div id="flash" className="flash">
            <div className="flash-error">{this.state.errorMsg}</div>
          </div>
          <button
            className="button l"
            type="button"
            onClick={this.retryClicked}
          >
            Retry payment
          </button>
        </Modal>
      );
    }
    return <div />;
  }
}

StripeComponent.propTypes = {
  state: PropTypes.oneOf(["Cart", "Sale", "Paid"]).isRequired,
  lineItemCollectionId: PropTypes.number.isRequired,
  amountInCents: PropTypes.number.isRequired,
  paymentUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  stripeKey: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onPaid: PropTypes.func.isRequired,
  StripeCheckout: PropTypes.object
};

export default StripeComponent;
