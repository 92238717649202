import map from "lodash/map";
import React from "react";
import PropTypes from "prop-types";

class InterviewQuestionsAndAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: props.faqs,
    };
    this.changeFaq = this.changeFaq.bind(this);
    this.addFaq = this.addFaq.bind(this);
  }

  changeFaq(index, field, value) {
    const faqs = map(this.state.faqs, (faq, findex)=>{
      if (findex === index) {
        return {
          ...faq,
          [field]: value
        }
      } else {
        return faq;
      }
    });
    this.setState({faqs});
  }

  addFaq() {
    const faqs = [
      ...this.state.faqs,
      {id: null, question: "", answer:"", _destroy:false}
    ];
    this.setState({faqs});
  }

  render(){
    const faqs = map(this.state.faqs, (faq, index) => faq._destroy ? this.renderRemovedFaq(faq, index) : this.renderPresentFaq(faq, index));
    return <div>
      {faqs}
      <fieldset className="inputs">
        <div className="full">
          <button type="button" className="addlink" onClick={this.addFaq}>Add Question and Answer Pair</button>
        </div>
      </fieldset>
    </div>;
  }

  renderPresentFaq(faq, index) {
    return <fieldset key={index} className="inputs">
      <ol>
        <input
          type="hidden"
          name={`interview[faqs_attributes][${index}][id]`}
          value={faq.id || ""}
        />
        <li className="full string input required stringish">
          <label>
            Question
            <textarea
              name={`interview[faqs_attributes][${index}][question]`}
              rows="2"
              value={faq.question}
              onChange={(ev) => {
                this.changeFaq(index, "question", ev.target.value)
              }}
            />
          </label>
        </li>

        <li className="full string input required stringish">
          <label>
            Answer
            <textarea
              name={`interview[faqs_attributes][${index}][answer]`}
              rows="4"
              value={faq.answer}
              onChange={(ev) => {
                this.changeFaq(index, "answer", ev.target.value)
              }}
            />
          </label>
        </li>

        <li className="full">
          <a href="#" className="deletelink" onClick={(ev) => {this.changeFaq(index, "_destroy", true)}}>Remove Q/A Pair</a>
        </li>
      </ol>
    </fieldset>;
  }

  renderRemovedFaq(faq, index) {
    return <fieldset key={index} className="inputs">
      <input
        type="hidden"
        name={`interview[faqs_attributes][${index}][id]`}
        value={faq.id || ""}
      />
      <input
        type="hidden"
        name={`interview[faqs_attributes][${index}][_destroy]`}
        value={true}
      />
      <div className="full">
        <a href="#" className="undolink" onClick={(ev) => {this.changeFaq(index, "_destroy", false)}}>Undo</a>
      </div>
    </fieldset>;
  }
}

InterviewQuestionsAndAnswers.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answer: PropTypes.string,
  })),
};

export default InterviewQuestionsAndAnswers;
