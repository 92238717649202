import React from "react";
import Li from "./Li";
import { Switch } from "antd";
import HiddenInput from "./HiddenInput";

const SwitchInput = ({ path, value, onChange }) => (
  <Li path={path}>
    <Switch
      checked={[true, "true"].includes(value)}
      onChange={checked => onChange(checked)}
      checkedChildren={<HiddenInput path={path} value={value} />}
      unCheckedChildren={<HiddenInput path={path} value={value} />}
    />
  </Li>
);

export default SwitchInput;
