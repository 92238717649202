import React from "react";
import PropTypes from "prop-types";
import WebsiteIdHiddenInput from "./WebsiteIdHiddenInput";
import RemoveWebsiteInput from "./RemoveWebsiteInput";

const RemovedWebsite = ({ id, index, linkable }) => [
  <RemoveWebsiteInput key={index} id={id} index={index} linkable={linkable} />,
  <WebsiteIdHiddenInput
    key={index + 1}
    index={index}
    linkable={linkable}
    value={id}
  />
];

RemovedWebsite.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number.isRequired,
  linkable: PropTypes.string.isRequired
};

export default RemovedWebsite;
