import React from "react";
import PropTypes from "prop-types";
import snakeCase from "lodash/snakeCase";
import classNames from "classnames";

export const Error = ({ visible, message }) =>
  visible ? <small className="signup_form__error">{message}</small> : null;

Error.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string
};

export const Suggestion = ({ message, onClick }) =>
  message ? (
    <small className={"signup_form__input--suggestion"}>
      Did you mean{" "}
      <button className={"signup_form__link"} onClick={onClick}>
        {message}
      </button>
      ?
    </small>
  ) : null;

Suggestion.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

const Input = ({
  name,
  label,
  type,
  model,
  onChange,
  onBlur,
  value,
  errorMsg,
  shouldMarkError,
  inputRef,
  children
}) => {
  const snakeCasedName = snakeCase(name);
  const inputType = type || "text";
  const inputName = (model == undefined ? snakeCasedName : `${model}[${snakeCasedName}]`);
  const inputId = (model == undefined ? snakeCasedName : `${model}_${snakeCasedName}`);
  const onChangeFn = onChange && onChange(name);
  const onBlurFn = onBlur && onBlur(name);
  const shouldMarkErrorResult = shouldMarkError && shouldMarkError(name);
  const className = classNames("signup_form__input", {
    "signup_form__input--error": shouldMarkErrorResult
  });

  return (
    <div>
      <label
        htmlFor={inputId}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <input
        type={inputType}
        name={inputName}
        id={inputId}
        value={value}
        onChange={onChangeFn}
        onBlur={onBlurFn}
        className={className}
        ref={inputRef}
      />
      <Error visible={shouldMarkErrorResult} message={errorMsg} />
      {children}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  errorMsg: PropTypes.string,
  shouldMarkError: PropTypes.func,
  inputRef: PropTypes.func,
  children: PropTypes.element
};

export default Input;
