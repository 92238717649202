import React from "react";
import { render } from "react-dom";
import AdvancedSearchSeasonOptions from "./AdvancedSearchSeasonOptions.js";

const container = document.getElementById("advanced_search_seasons");
if (container) {
  const props = JSON.parse(container.getAttribute("data-react-props"));
  render(
    <AdvancedSearchSeasonOptions
      seasons={props.seasons}
      years={props.years}
      releaseOnFrom={props.release_on_from}
      releaseOnTo={props.release_on_to}
      year={props.year_available}
      season={props.season_available}
    />,
    container
  );
}
