import React from "react";
import { inputId, inputName } from "../utils";

const WebsiteIdHiddenInput = ({ value, linkable, index }) => {
  return value ? (
    <input
      type="hidden"
      value={`${value}`}
      name={inputName(linkable, index, "id")}
      id={inputId(linkable, index, "id")}
    />
  ) : null;
};

export default WebsiteIdHiddenInput;
