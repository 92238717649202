import React from "react";

const Li = ({ path, className = "full", label, children }) => (
  <li
    className={`${className} input required`}
    id={`config_${path}_value_input`}
  >
    {label}
    {children}
  </li>
);

export default Li;
