function extractFirstErrorMessageFromJsonApiReponse(jqXHR, defaultMsg) {
  var errorMsg = `${defaultMsg}, unknown error occurred.`;
  if (jqXHR.readyState == 4) {
    try {
      const responseJson = JSON.parse(jqXHR.responseText);
      errorMsg = responseJson.errors[0].title;
    } catch (e) {
    }
  } else {
    errorMsg = `${defaultMsg}, networking error occurred.`;
  }
  return errorMsg;
}

export {extractFirstErrorMessageFromJsonApiReponse};