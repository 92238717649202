import React, { useState } from "react";
import Select from "react-select";
import styles from "../selectStyles.js";
import snakeCase from "lodash/snakeCase";
import isEmpty from "lodash/isEmpty";
import { getCSRFToken } from "../utils";
import isValid from "./isValid";
import {
  MATCHING,
  NON_MATCHING,
  ANY,
  MATCHING_PUBLISHER,
  MATCHING_IMPRINT,
} from "./constants";

export default ({ sites, formats, publishers, imprints, url }) => {
  const [choices, choose] = useState({
    rule: MATCHING,
    siteId: null,
    formatId: null,
    publisherId: null,
    imprintId: null,
  });

  const mkSelect = (options, keyName, label) => {
    const getValues = options =>
      options.filter(option => option.value === choices[keyName]);

    return (
      <React.Fragment>
        <label className="label">{label}</label>
        <Select
          name={snakeCase(keyName)}
          styles={styles}
          isClearable={false}
          backspaceRemovesValue={false}
          options={options}
          value={getValues(options)}
          onChange={({ value }) => {
            choose({ ...choices, [keyName]: value });
          }}
        />
      </React.Fragment>
    );
  };

  const rules = [
    { value: MATCHING, label: "Matching format" },
    { value: NON_MATCHING, label: "Not matching format" },
    { value: ANY, label: "Matching any format" },
    { value: MATCHING_PUBLISHER, label: "Matching publisher" },
    { value: MATCHING_IMPRINT, label: "Matching imprint" },
  ];

  const oneThird = {
    width: "33%",
    padding: "0 .5em .5em 0"
  };

  const selectors = (
    <form className="formtastic" method="post" action={url}>
      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      <fieldset className="inputs">
        <ol>
          <li className="select input" style={oneThird}>
            {mkSelect(sites, "siteId", "Site")}
          </li>
          <li className="select input" style={oneThird}>
            {mkSelect(rules, "rule", "Rule")}
          </li>
          <li className="select input" style={oneThird}>
            { {
              [MATCHING]:           mkSelect(formats, "formatId", "Format"),
              [NON_MATCHING]:       mkSelect(formats, "formatId", "Format"),
              [MATCHING_PUBLISHER]: mkSelect(publishers, "publisherId", "Publisher"),
              [MATCHING_IMPRINT]:   mkSelect(imprints, "imprintId", "Imprint"),
              [ANY]:                null,
            }[choices.rule] }
          </li>
        </ol>
      </fieldset>
      { isValid(choices) &&
        <fieldset className="actions">
          <ol>
            <li className="action button_action">
              <button name="button" type="submit">
                Add Rule
              </button>
            </li>
          </ol>
        </fieldset>
      }
    </form>
  );

  return selectors;
};
