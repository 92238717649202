import moment from "moment";

const validateCreditCardDate = (month, year) => {
  if (month.length === 0 || year.length === 0) {
    return true;
  } else {
    const format = year.length === 4 ? "YYYYMM" : "YYMM";
    const date = moment(`${year}${month.padStart(2, 0)}`, format);
    return date.isValid() && moment() < date.add(1, "months");
  }
};

const luhnCheck = number => {
  let splittedNumber = (number + "")
    .split("")
    .reverse()
    .map(x => parseInt(x));
  let lastDigit = splittedNumber.splice(0, 1)[0];
  let sum = splittedNumber.reduce(
    (acc, digit, i) => (i % 2 !== 0 ? acc + digit : acc + (digit * 2) % 9 || 9),
    0
  );
  sum += lastDigit;
  return sum % 10 === 0;
};

const shippingAddress = ({
  useShippingAddress,
  shippingAddressName,
  shippingAddressStreet,
  shippingAddressCity,
  shippingAddressPostalCode,
  shippingAddressCountryId
}) =>
  useShippingAddress
    ? {
        shippingAddressName: shippingAddressName.length === 0,
        shippingAddressStreet: shippingAddressStreet.length === 0,
        shippingAddressCity: shippingAddressCity.length === 0,
        shippingAddressPostalCode: shippingAddressPostalCode.length === 0,
        shippingAddressCountryId: shippingAddressCountryId.length === 0
      }
    : {
        shippingAddressName: false,
        shippingAddressStreet: false,
        shippingAddressCity: false,
        shippingAddressPostalCode: false,
        shippingAddressCountryId: false
      };

const creditCard = ({
  paymentMethod,
  authorizationName,
  authorizationNumber,
  authorizationMonth,
  authorizationYear,
  authorizationVerificationValue
}) =>
  paymentMethod === "authorize_net"
    ? {
        authorizationName: authorizationName.length === 0,
        authorizationNumber:
          authorizationNumber.length === 0 ||
          authorizationNumber.length < 12 ||
          !luhnCheck(authorizationNumber),
        authorizationMonth:
          authorizationMonth.length === 0 ||
          !validateCreditCardDate(authorizationMonth, authorizationYear),
        authorizationYear:
          authorizationYear.length === 0 ||
          !validateCreditCardDate(authorizationMonth, authorizationYear),
        authorizationVerificationValue:
          authorizationVerificationValue.length < 3 ||
          authorizationVerificationValue.length > 4
      }
    : {
        authorizationName: false,
        authorizationNumber: false,
        authorizationMonth: false,
        authorizationYear: false,
        authorizationVerificationValue: false
      };

const validate = ({
  email,
  emailConfirmation,
  billingAddressName,
  billingAddressStreet,
  billingAddressCity,
  billingAddressPostalCode,
  billingAddressCountryId,
  shippingAddressName,
  shippingAddressStreet,
  shippingAddressCity,
  shippingAddressPostalCode,
  shippingAddressCountryId,
  authorizationName,
  authorizationNumber,
  authorizationMonth,
  authorizationYear,
  authorizationVerificationValue,
  useShippingAddress,
  paymentMethod
}) => ({
  email: email.length === 0,
  emailConfirmation:
    emailConfirmation.length === 0 || email !== emailConfirmation,
  billingAddressName: billingAddressName.length === 0,
  billingAddressStreet: billingAddressStreet.length === 0,
  billingAddressCity: billingAddressCity.length === 0,
  billingAddressPostalCode: billingAddressPostalCode.length === 0,
  billingAddressCountryId: billingAddressCountryId.length === 0,
  ...shippingAddress({
    useShippingAddress,
    shippingAddressName,
    shippingAddressStreet,
    shippingAddressCity,
    shippingAddressPostalCode,
    shippingAddressCountryId
  }),
  ...creditCard({
    paymentMethod,
    authorizationName,
    authorizationNumber,
    authorizationMonth,
    authorizationYear,
    authorizationVerificationValue
  })
});

export default validate;
