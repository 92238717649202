import React from "react";
import PropTypes from "prop-types";
import Website from "./components/Website";
import RemovedWebsite from "./components/RemovedWebsite";
import AddWebsiteLink from "./components/AddWebsiteLink";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";

const blankWebsite = { id: null, website: null, role: null, destroy: false };

const websiteComponents = ({
  linkable,
  roles,
  websites,
  removeWebsiteHandler,
  removeIcon,
  requiredIcon
}) => {
  return map(websites, (website, index) =>
    website.destroy ? (
      <RemovedWebsite
        key={index}
        linkable={linkable}
        index={index}
        id={website.id}
      />
    ) : (
      <Website
        key={index}
        linkable={linkable}
        index={index}
        roles={roles}
        id={website.id}
        url={website.url}
        selectedRole={website.role}
        title={website.title}
        removeWebsiteHandler={removeWebsiteHandler}
        removeIcon={removeIcon}
        requiredIcon={requiredIcon}
      />
    )
  );
};

class WebsitesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      websites: compact([
        ...map(props.websites, website => ({ ...website, destroy: false })),
        isEmpty(props.websites) ? blankWebsite : null
      ])
    };
  }

  addBlankWebsite = event => {
    this.setState({
      ...this.state,
      websites: [...this.state.websites, blankWebsite]
    });
    event.preventDefault();
  };

  removeWebsiteHandler = index => {
    const websiteToRemove = this.state.websites[index];
    this.setState({
      ...this.state,
      websites: [
        ...this.state.websites.slice(0, index),
        { ...websiteToRemove, destroy: true },
        ...this.state.websites.slice(index + 1)
      ]
    });
  };

  render() {
    return (
      <fieldset className="inputs">
        {websiteComponents({
          ...this.props,
          websites: this.state.websites,
          removeWebsiteHandler: this.removeWebsiteHandler
        })}
        <AddWebsiteLink onClick={this.addBlankWebsite} />
      </fieldset>
    );
  }
}

WebsitesForm.propTypes = {
  linkable: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })
  ).isRequired,
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.number
    })
  ),
  removeIcon: PropTypes.string.isRequired,
  requiredIcon: PropTypes.string.isRequired
};

export default WebsitesForm;
